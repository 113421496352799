<template>
	<div class="page">
		<div class="title">标准合同列表</div>

		<el-divider></el-divider>

		<!-- filter -->
		<div class="filter_box">
			<div class="filter_search">
				<!-- input -->
				<el-input class="filter_search_input" placeholder="请输入关键词" v-model="search.keyword" clearable>
				</el-input>
				<div class="custom_button no_select" @click="toSearch">搜索</div>
			</div>
		</div>

		<!-- table -->
		<el-table id="table" :data="tableData" :header-cell-style="tableHeaderStyle" :cell-style="tableCellStyle"
			style="width: 100%;">
			<el-table-column prop="title" label="合同名称"></el-table-column>
			<!-- <el-table-column prop="" label="适用行业"></el-table-column>
            <el-table-column prop="" label="适用员工类型"></el-table-column>
            <el-table-column
                prop="useCount"
                label="在用企业数"
            ></el-table-column> -->
			<el-table-column prop="add_date" label="最近更新时间"></el-table-column>
			<el-table-column label="操作" min-width="200" fixed="right">
				<template v-slot:default="scope">
					<el-dropdown size="mini" split-button type="primary">
						操作
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="downloadTemplate(scope.row.template_id)">导出本地</el-dropdown-item>
							<el-dropdown-item @click.native="useTemplate(scope.row)">使用合同</el-dropdown-item>
							<el-dropdown-item @click.native="editTemplate(scope.row)">编辑合同</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <div class="operations">
						<div @click="downloadTemplate(scope.row.template_id)">导出本地</div>
						<div @click="useTemplate(scope.row)">使用合同</div>
						<div @click="editTemplate(scope.row)">编辑合同</div>
					</div> -->
				</template>
			</el-table-column>
		</el-table>

		<el-pagination layout="prev, pager, next" :total="page.total" :page-size="page.size"
			:current-page.sync="page.page" @current-change="loadData">
		</el-pagination>

		<el-dialog title="编辑合同" :visible.sync="dialogFormVisible" :close-on-click-modal="false" :close-on-press-escape="false">
			<quill-editor ref="myTextEditor" v-model="content" :options="editorOption" @blur="onEditorBlur($event)"
				@focus="onEditorFocus($event)" @ready="onEditorReady($event)">
			</quill-editor>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";

	export default {
		data() {
			return {
				search: {
					keyword: "",
					date: "",
				},
				tableData: [],
				// 分页参数
				page: {
					size: 15,
					page: 1,
					total: 0,
				},
				dialogFormVisible: false,
				content: '',
				editorOption: {
					theme: 'snow',
					boundary: document.body,
					modules: {
						toolbar: [
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{
								'header': 1
							}, {
								'header': 2
							}],
							[{
								'list': 'ordered'
							}, {
								'list': 'bullet'
							}],
							[{
								'script': 'sub'
							}, {
								'script': 'super'
							}],
							[{
								'indent': '-1'
							}, {
								'indent': '+1'
							}],
							[{
								'direction': 'rtl'
							}],
							[{
								'size': ['small', false, 'large', 'huge']
							}],
							[{
								'header': [1, 2, 3, 4, 5, 6, false]
							}],
							[{
								'color': []
							}, {
								'background': []
							}],
							[{
								'font': []
							}],
							[{
								'align': []
							}],
							['clean'],
							// ['link', 'image', 'video']
						]
					},
				},
				tableHeaderStyle: {
					backgroundColor: "#F5F8FA",
					textAlign: "center",
				},
				tableCellStyle: {
					textAlign: "center",
				},
			};
		},
		created() {
			this.loadData = this.loadDataFactory();
			this.loadData();
		},
		methods: {
			...mapActions("contract", [
				"getContractTemplateList",
				"useFddContractTemplate",
			]),

			loadDataFactory(keyword = null) {
				let params = {};
				if (keyword) {
					params.keywords = keyword;
				}
				return () => {
					params.page = this.page.page;
					let loading = this.$loading();
					this.getContractTemplateList(params)
						.then((res) => {
							console.log(res);
							this.tableData = res.data.data.list;
							this.page.total = res.data.data.total;
							this.$nextTick(() => {
								loading.close();
							});
						})
						.catch((e) => {
							this.$message.error(e.msg);
							this.$nextTick(() => {
								loading.close();
							});
						});
				};
			},

			/**
			 * 搜索
			 */
			toSearch() {
				this.page.page = 1;
				// 生产带有搜索参数的函数
				this.loadData = this.loadDataFactory(this.search.keyword);
				this.loadData();
			},

			openWindow(url) {
				window.open(url, "_blank");
			},

			downloadTemplate(template_id) {
				this.openWindow(
					`${process.env.VUE_APP_BASE_API}/contract/fdd_template/download?template_id=${template_id}`
				);
			},

			useTemplate(row) {
				let loading = this.$loading();
				this.useFddContractTemplate({
						fdd_template_id: row.template_id
					})
					.then((res) => {
						console.log(res);
						this.$message.success(res.data.msg);
						this.$router.push({
							name: "StandardContractsList"
						});
						this.$nextTick(() => {
							loading.close();
						});
					})
					.catch((e) => {
						console.error(e);
						this.$message.error(e.msg);
						this.$nextTick(() => {
							loading.close();
						});
					});
			},

			editTemplate(row) {
				console.log(row)
				this.dialogFormVisible = true
			},
			onEditorBlur(editor) {
				console.log('editor blur!', editor)
			},
			onEditorFocus(editor) {
				console.log('editor focus!', editor)
			},
			onEditorReady(editor) {
				console.log('editor ready!', editor)
			},
			onEditorChange({
				editor,
				html,
				text
			}) {
				console.log('editor change!', editor, html, text)
				this.content = html
			}
		},
	};
</script>

<style lang="scss" scoped>
	.title {
		font-size: 14px;
		font-weight: bold;
		color: #1c2438;
	}

	.filter_box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 30px 0;

		.el-date-editor {
			height: 36px;
			margin-right: 10px;

			::v-deep .el-input__inner {
				height: 36px;
			}
		}

		.filter_search {
			display: flex;
			align-items: center;

			.filter_search_input {
				width: 350px;
				height: 36px;
				margin-right: 10px;

				::v-deep .el-input__inner {
					height: 36px;
				}
			}
		}

		.filter_btns {
			display: flex;
			align-items: center;

			>div:not(:first-child) {
				margin-left: 20px;
			}
		}
	}

	.operations {
		display: flex;
		justify-content: center;
		align-items: center;

		>div {
			font-size: 14px;
			color: #406bff;
			cursor: pointer;

			&:not(:last-child) {
				margin-right: 13px;
			}
		}
	}
</style>
